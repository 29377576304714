<template>
  <v-menu
    v-model="visible"
    :ref="menuName"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="290px"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateFormatted"
        v-bind="attrs"
        v-on="on"
        :dense="dense"
        :label="fieldLabel"
        :prepend-icon="iconCalendar"
        :clearable="clearable"
        :disabled="disabled"
        :error-messages="computedErrorMessages"
        @click:clear="onClear()"
        @change="onInput(dateFormatted)"
        :readonly="readonly"
      >
        <template v-slot:prepend>
          <slot name="prepend"></slot>
        </template>
        <template v-slot:append>
          <slot name="append"></slot>
        </template>
        <template v-slot:append-outer>
          <slot name="append-outer"></slot>
        </template>
      </v-text-field>
    </template>

    <v-date-picker
      v-model="dateIsoString"
      :disabled="disabled"
      :readonly="readonly"
      no-title
      scrollable
    >
      <v-spacer></v-spacer>
      <base-button @click="onCancel">{{ labelCancel }}</base-button>
      <base-button @click="onOK">{{ labelOK }}</base-button>
    </v-date-picker>
  </v-menu>
</template>

<script>
// design
import { iconCalendar } from "@/design/icon/iconConst";

// filters & utils
import { dateISOString, isValidDate, isValidDateObject } from "@/utils";

// model
import { commandNames } from "@/model/common/commands/commandModel";
import {
  formatSpecifiedDate,
  monthDayYearDash,
  validateDate,
  yearMonthDayDash,
  yearMonthDaySlash,
  monthDayYearSlash
} from "@/utils/moment";

const defaultFieldName = "Date";

export default {
  inheritAttrs: false,
  name: "DatePicker",
  components: {
    BaseButton: () => import("@/components/shared/base/BaseButton")
  },
  data() {
    return {
      date: undefined,
      dateIsoString: undefined,
      dateFormatted: "",
      errorMessages: undefined,
      visible: false,
      iconCalendar: iconCalendar,
      // Labels
      labelCancel: commandNames.cancel,
      labelOK: commandNames.ok
    };
  },
  props: {
    /**
     * Data getFieldAssetName label
     */
    fieldLabel: {
      type: String,
      default: defaultFieldName
    },
    /**
     * Data getFieldAssetName name
     */
    fieldName: {
      type: String,
      default: defaultFieldName
    },
    /**
     * Data item
     */
    item: {
      type: Object,
      default: null
    },
    /**
     * Default Current Date
     */
    defaultCurrentDate: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    /**
     * Is Read Only
     */
    readonly: {
      type: Boolean,
      default: true
    },
    /**
     * Is disabled
     */
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    menuName() {
      return `menu${this.fieldName}`;
    },
    computedErrorMessages() {
      return this.errorMessages;
    }
  },
  methods: {
    onInput(val) {
      try {
        if (this.readonly) return;

        const isValidDate = validateDate(
          val,
          [
            monthDayYearDash,
            monthDayYearSlash,
            yearMonthDayDash,
            yearMonthDaySlash
          ],
          false
        );
        if (isValidDate) {
          console.log(
            `${this.$options.name} onInput isValidDate: `,
            isValidDate
          );
          this.dateIsoString = formatSpecifiedDate(val, yearMonthDayDash);
          this.onOK();
        } else {
          this.dateIsoString = undefined;
          this.dateFormatted = "";
        }
      } catch (e) {
        this.errorMessages = e?.toString();
      }
    },
    onCancel() {
      this.visible = false;
    },
    onOK() {
      try {
        this.errorMessages = undefined;
        this.$refs[this.menuName].save(this.dateIsoString);
        this.item[this.fieldName] = this.formatDate(this.dateIsoString);
      } catch (e) {
        this.errorMessages = e?.toString();
      }
    },
    onClear() {
      try {
        this.errorMessages = undefined;
        this.item[this.fieldName] = undefined;
      } catch (e) {
        this.errorMessages = e?.toString();
      }
    },
    /**
     * format Date
     * @param dateIsoString ISO 8601 date strings (YYYY-MM-DD)
     * @return {string} formatted date string (MM-DD-YYYY)
     */
    formatDate(dateIsoString) {
      try {
        this.errorMessages = undefined;
        if (!dateIsoString) return "";

        const [year, month, day] = dateIsoString.split("-");
        return `${month}/${day}/${year}`;
      } catch (e) {
        this.errorMessages = e?.toString();
      }
    },
    displayDate(item) {
      try {
        this.errorMessages = undefined;
        const dateValue = item ? item[this.fieldName] : null;

        if (isValidDate(dateValue)) {
          this.date = isValidDateObject(dateValue)
            ? dateValue
            : new Date(dateValue);
        } else {
          if (this.defaultCurrentDate) {
            this.date = new Date();
          } else {
            this.date = undefined;
          }
        }

        //
        // Handles v-date-picker Caveats
        //
        // v-date-picker accepts ISO 8601 date strings (YYYY-MM-DD).
        // For more information regarding ISO 8601 and other standards,
        // visit the official ISO (International Organization for Standardization)
        //
        this.dateIsoString = this.date ? dateISOString(this.date) : "";
      } catch (e) {
        this.errorMessages = e?.toString();
      }
    }
  },
  created() {
    try {
      this.errorMessages = undefined;
      this.displayDate(this.item);
    } catch (e) {
      this.errorMessages = e?.toString();
    }
  },
  watch: {
    item(itemNew) {
      try {
        this.errorMessages = undefined;
        this.displayDate(itemNew);
      } catch (e) {
        this.errorMessages = e?.toString();
      }
    },
    dateIsoString(dateNew) {
      try {
        this.errorMessages = undefined;
        // console.log(`watch changed dateIsoString:`, dateNew);
        //
        // Apply date format for display
        //
        this.dateFormatted = this.formatDate(dateNew);
      } catch (e) {
        this.errorMessages = e?.toString();
      }
    }
  }
};
</script>
