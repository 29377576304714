// A JavaScript date library for parsing, validating, manipulating, and formatting dates
// https://momentjs.com/docs/#/parsing/

import moment from "moment";

const yearMonthDayDash = "YYYY-MM-DD";
const monthDayYearDash = "MM-DD-YYYY";
const yearMonthDaySlash = "YYYY/MM/DD";
const monthDayYearSlash = "MM/DD/YYYY";

/**
 * Get Current Date and time
 * RFC 3339 standard
 */
const currentDateTime = () => {
  return moment().format();
};

/**
 * Get Current Date and time
 * IS0 8061 standard
 */
const currentDateTimeISO = () => {
  return moment().toISOString();
};

/**
 * Get Moment Instance
 * used to perform many date/time operations supported by moment library
 * @param date
 * @param {string|Array[String]} format
 * @param {Boolean} strict - Strict mode requires the input to the moment to exactly match the specified format, including separators
 * @return {Object}
 */
const getMomentInstance = (date = "", format = "", strict = true) => {
  return moment(date, format, strict);
};

/**
 * Verify if date is valid
 * @param date
 * @param {string|Array[String]} format
 * @param {Boolean} strict - Strict mode requires the input to the moment to exactly match the specified format, including separators
 * @return {boolean}
 */
const validateDate = (date, format = "", strict = true) => {
  const result = moment(date, format, strict);
  return result.isValid();
};

/**
 * Format Date
 * @param {String} dateFormat
 * @return {string}
 */
const formatCurrentDate = (dateFormat = monthDayYearDash) => {
  return moment().format(dateFormat);
};

/**
 * Format Specific Date
 * @param date
 * @param {String} dateFormat
 * @return {string}
 */
const formatSpecifiedDate = (date, dateFormat = monthDayYearDash) => {
  return moment(date).format(dateFormat);
};

export {
  validateDate,
  currentDateTime,
  currentDateTimeISO,
  getMomentInstance,
  formatCurrentDate,
  formatSpecifiedDate,
  yearMonthDayDash,
  monthDayYearDash,
  yearMonthDaySlash,
  monthDayYearSlash
};
